import { AxiosError } from "axios";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { useQuery, UseQueryResult } from "react-query";
import { IOrganization, Organizations } from "@metronome/types/Organization";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { paths } from "@metronome/constants/paths";

import api, { ExpectedErrorResponseType, HTTPMethod } from "./api";

const GetOrganizationRequest = z.void();
const GetOrganizationResponse = Organizations;

const fetchOrganizations = api<
	z.infer<typeof GetOrganizationRequest>,
	z.infer<typeof GetOrganizationResponse>
>({
	method: HTTPMethod.enum.GET,
	requestSchema: GetOrganizationRequest,
	responseSchema: GetOrganizationResponse,
});

// these paths are workspace wide and not specific to an organization
const noOrganizationCallsPaths = [
	"/notifications",
	"/onboarding",
	"/error",
	"/goto",
];

const isEnabled = (path: string): boolean =>
	!noOrganizationCallsPaths.some((substr) => path.startsWith(substr));

export default function useOrganizations(
	onError: (errorPath: string) => void,
): UseQueryResult<
	Array<Partial<IOrganization>>,
	AxiosError<ExpectedErrorResponseType>
> {
	const location = useLocation();
	const workspaceId = useWorkspaceId();

	return useQuery(
		[workspaceId, "organizations"],
		() => fetchOrganizations(`ws/${workspaceId}/organizations`),
		{
			enabled: !!workspaceId && isEnabled(location.pathname),
			refetchOnWindowFocus: false,
			retry: false,
			onError: (err) => {
				onError(
					err.response?.status === 404
						? `${paths.onboarding}?from=404`
						: `${paths.onboarding}`,
				);
			},
		},
	);
}
