interface PathParams {
	workspaceId?: string;
	processStreamId?: string;
	processInstanceId?: string;
	stepInstanceId?: string;
	processTemplateId?: string;
	stepTemplateId?: string;
}

const DefaultPathParams: PathParams = {
	workspaceId: "workspaceId",
	processStreamId: "processStreamId",
	processInstanceId: "processInstanceId",
	stepInstanceId: "stepInstanceId",
	processTemplateId: "processTemplateId",
	stepTemplateId: "stepTemplateId",
};

export const paths = {
	root: "/",
	onboarding: "/onboarding",
	error: "/error",
	errors: {
		notFound: "/error/404",
		serverError: "/error/500",
		noWorkspace: "/error/no-workspace",
		forbiddenWorkspace: "/error/forbidden-workspace",
		noSelf: "/error/no-self",
	},
	login: "/authentication/login",

	goto: "/goto",

	yourWork: `/:${DefaultPathParams.workspaceId}/your-work`,
	yourWorkSubPath: {
		stepInstances: `/:${DefaultPathParams.workspaceId}/your-work/step`,
		stepInstancesModal: `/:${DefaultPathParams.workspaceId}/your-work/step/modal/step-instance/:${DefaultPathParams.stepInstanceId}`,
		processInstances: `/:${DefaultPathParams.workspaceId}/your-work/process`,
	},

	processesOverview: `/:${DefaultPathParams.workspaceId}/streams`,

	activities: `/:${DefaultPathParams.workspaceId}/activities`,

	processStream: `/:${DefaultPathParams.workspaceId}/stream/:${DefaultPathParams.processStreamId}`,
	processStreamSubPath: {
		active: `/:${DefaultPathParams.workspaceId}/stream/:${DefaultPathParams.processStreamId}/active`,
		upcoming: `/:${DefaultPathParams.workspaceId}/stream/:${DefaultPathParams.processStreamId}/upcoming`,
		history: `/:${DefaultPathParams.workspaceId}/stream/:${DefaultPathParams.processStreamId}/history`,
	},

	processInstance: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}`,
	processInstanceSubPath: {
		gatesAndSteps: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/gates-and-steps`,
		gatesAndStepsViewStepInstance: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/gates-and-steps/view/:${DefaultPathParams.stepInstanceId}`,
		notes: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/notes`,
		attachments: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/attachments`,
		histories: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/history`,
	},

	stepInstance: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/step/:${DefaultPathParams.stepInstanceId}`,
	stepInstanceSubPath: {
		activities: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/step/:${DefaultPathParams.stepInstanceId}/agenda-activities`,
		attachments: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/step/:${DefaultPathParams.stepInstanceId}/attachments`,
		notes: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/step/:${DefaultPathParams.stepInstanceId}/notes`,
		forms: `/:${DefaultPathParams.workspaceId}/process/:${DefaultPathParams.processInstanceId}/step/:${DefaultPathParams.stepInstanceId}/forms`,
	},
	workload: `/:${DefaultPathParams.workspaceId}/workload`,
	content: `/:${DefaultPathParams.workspaceId}/content`,
	notifications: "/notifications",
	templatePreview: `/:${DefaultPathParams.workspaceId}/process-templates-preview/:${DefaultPathParams.processTemplateId}`,
	templatePreviewView: `/:${DefaultPathParams.workspaceId}/process-templates-preview/:${DefaultPathParams.processTemplateId}/steps/:${DefaultPathParams.stepTemplateId}`,
};

export const getRoute = (path: string, params?: PathParams): string => {
	let route = path;
	if (path && params) {
		const keys = Object.keys(params);
		// biome-ignore lint/complexity/noForEach: <explanation>
		keys.forEach((key) => {
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			route = route.replace(`:${key}`, (params as any)[key]);
		});
	}
	return route;
};
