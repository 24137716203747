import { Login } from "./Login";
import styles from "./authentication.module.scss";

export function AuthenticationPage(): JSX.Element {
	return (
		<div className={styles.container}>
			<Login />
		</div>
	);
}
