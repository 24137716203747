type onMessageCallback = (message?: unknown) => void;
type Callback = (e: Event | MessageEvent) => void;
interface EventListenersInterface {
	onopen: Array<Callback>;
	onmessage: Array<Callback>;
	onerror: Array<Callback>;
	onclose: Array<Callback>;
}

export const EVENT_LISTENERS_OBJECT: EventListenersInterface = {
	onopen: [],
	onmessage: [],
	onerror: [],
	onclose: [],
};

export interface WebsocketManagerParams {
	userId: string;
	eventListeners: EventListenersInterface;
	connectTimeout?: number;
}

export default class WebsocketManager {
	userId: string;
	ws: WebSocket | null;
	maxReconnectAttempts: number;
	eventListeners: EventListenersInterface;
	binaryType?: BinaryType;
	intervalId: NodeJS.Timeout | undefined;
	connectTimeout: number;

	#attemptNumber = 0;

	constructor(props: WebsocketManagerParams) {
		const {
			userId,
			eventListeners = EVENT_LISTENERS_OBJECT,
			connectTimeout,
		} = props;
		this.eventListeners = eventListeners;
		this.ws = null;
		this.userId = userId;
		this.intervalId = undefined;
		this.maxReconnectAttempts = 3;
		this.connectTimeout = connectTimeout ?? 5000;

		this.attachEventListeners = this.attachEventListeners.bind(this);
		this.removeEventListeners = this.removeEventListeners.bind(this);
		this.connect = this.connect.bind(this);
		this.reconnect = this.reconnect.bind(this);
		this.pingHost = this.pingHost.bind(this);
		this.connect();
	}

	attachEventListeners() {
		Object.keys(EVENT_LISTENERS_OBJECT).forEach((event) => {
			const typedEvent = event as keyof EventListenersInterface;
			if (this.ws) {
				this.ws[typedEvent] = (e: Event | MessageEvent) => {
					this.eventListeners[typedEvent].map((listener) => listener(e));
				};
				if (typedEvent === "onclose") {
					this.ws[typedEvent] = (e: Event | MessageEvent) => {
						this.eventListeners[typedEvent].map((listener) => listener(e));
						this.removeEventListeners();
						this.close();
						this.reconnect();
					};
				}
			}
		});
	}

	removeEventListeners() {
		Object.keys(EVENT_LISTENERS_OBJECT).forEach((event) => {
			const typedEvent = event as keyof EventListenersInterface;
			if (this.ws) {
				this.ws[typedEvent] = null;
			}
		});
	}

	connect() {
		this.ws = new WebSocket(
			window.VITE_WEBSOCKET_URL || import.meta.env.VITE_WEBSOCKET_URL,
			[this.userId],
		);
		this.attachEventListeners();
		this.pingHost();
	}

	pingHost() {
		if (this.ws) {
			const interval = setInterval(() => {
				if (this.ws?.readyState === this.ws?.OPEN) {
					this.ws?.send("PING");
				}
			}, 60 * 1000);
			this.intervalId = interval;
		}
	}

	close() {
		if (this.ws) {
			this.ws.close();
			clearInterval(this.intervalId);
		}
	}

	reconnect() {
		this.#attemptNumber += 1;
		if (this.#attemptNumber <= this.maxReconnectAttempts) {
			setTimeout(this.connect, this.connectTimeout);
		}
	}
}
