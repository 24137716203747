import type React from "react";
import { createContext, useContext, useMemo, useState } from "react";

type ProcessCategoriesModel = {
	activeProcessCategories: string[];
	setActiveProcessCategories: React.Dispatch<React.SetStateAction<string[]>>;
};

const ProcessCategoriesContext = createContext<ProcessCategoriesModel>({
	activeProcessCategories: [],
	setActiveProcessCategories: () => null,
});

const EMPTY_ARRAY: string[] = [];
const ProcessCategoriesProvider: React.FC = ({ children }) => {
	const [activeProcessCategories, setActiveProcessCategories] =
		useState<string[]>(EMPTY_ARRAY);

	const value = useMemo(
		() => ({
			activeProcessCategories,
			setActiveProcessCategories,
		}),
		[activeProcessCategories],
	);

	return (
		<ProcessCategoriesContext.Provider value={value}>
			{children}
		</ProcessCategoriesContext.Provider>
	);
};

const useProcessCategoriesData = (): ProcessCategoriesModel => {
	const context = useContext(ProcessCategoriesContext);
	if (!context) {
		throw new Error("useProcessCategories must be used within a provider");
	}
	return context;
};

export { useProcessCategoriesData, ProcessCategoriesProvider };
