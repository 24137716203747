import {
	type Configuration,
	LogLevel,
	ProtocolMode,
	PublicClientApplication,
	type SilentRequest,
} from "@azure/msal-browser";

const activateIdentity = window.localStorage.getItem("activateIdentity");
const hasSelectedOpenId = JSON.parse(activateIdentity) === "true";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const system = {
	iframeHashTimeout: 10000,
	loggerOptions: {
		loggerCallback: (
			level: LogLevel,
			message: string,
			containsPii: boolean,
		): void => {
			if (containsPii) {
				return;
			}
			switch (level) {
				case LogLevel.Error:
					console.error(message);
					break;
				default:
					break;
			}
		},
		piiLoggingEnabled: false,
	},
};

const cache = {
	cacheLocation: "localStorage", // This configures where your cache will be stored
	storeAuthStateInCookie: isIE || isEdge || isFirefox,
};

const msalConfig: Configuration = {
	auth: {
		clientId:
			window.VITE_AZUREAD_CLIENT_ID ||
			import.meta.env.VITE_AZUREAD_CLIENT_ID ||
			"",
		authority:
			window.VITE_AZUREAD_AUTHORITY ||
			import.meta.env.VITE_AZUREAD_AUTHORITY ||
			"",
		navigateToLoginRequestUrl: true,
		postLogoutRedirectUri: window.location.origin,
		redirectUri: window.location.origin,
	},
	cache,
	system,
};

const identityConfig: Configuration = {
	auth: {
		clientId:
			window.VITE_OPENID_CLIENT_ID ||
			import.meta.env.VITE_OPENID_CLIENT_ID ||
			"",
		redirectUri:
			window.VITE_PUBLIC_URL ||
			import.meta.env.VITE_PUBLIC_URL ||
			window.location.origin,
		authority:
			window.VITE_OPENID_AUTHORITY || import.meta.env.VITE_OPENID_AUTHORITY,
		navigateToLoginRequestUrl: true,
		postLogoutRedirectUri: window.location.origin,
		protocolMode: ProtocolMode.OIDC,
		knownAuthorities: [
			window.VITE_OPENID_AUTHORITY || import.meta.env.VITE_OPENID_AUTHORITY,
		],
	},
	cache,
	system,
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: SilentRequest = {
	scopes: hasSelectedOpenId
		? [
				window.VITE_OPENID_API_SCOPE ||
					import.meta.env.VITE_OPENID_API_SCOPE ||
					"",
		  ]
		: [
				window.VITE_AZUREAD_API_SCOPE ||
					import.meta.env.VITE_AZUREAD_API_SCOPE ||
					"",
		  ],
};

const msalInstance = new PublicClientApplication(
	hasSelectedOpenId ? identityConfig : msalConfig,
);

export { msalInstance };
