import { getRoute, paths } from "@metronome/constants/paths";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { IUser } from "@metronome/types/User";
import { AxiosError, AxiosResponse } from "axios";
import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from "react-query";
import { useHistory } from "react-router-dom";

import { apiGet, apiPut, ExpectedErrorResponseType } from "./api";

export default function useSelf(): UseQueryResult<IUser | undefined, Error> {
	const { activeOrganization } = useOrganizationData();
	return useQuery(
		["self", activeOrganization],
		() =>
			apiGet<IUser>("/self", {
				params: {
					organizationId: activeOrganization,
				},
			}),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
			retry: 3,
		},
	);
}

export function useSelfUpdate(): UseMutationResult<
	AxiosResponse<IUser> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ defaultWorkspaceId?: string }
> {
	const queryClient = useQueryClient();
	const history = useHistory();
	return useMutation(
		({ defaultWorkspaceId }) =>
			apiPut("self", {
				defaultWorkspaceId,
			}),
		{
			onSuccess: (response, { defaultWorkspaceId }) => {
				const { data } = response;
				queryClient.setQueryData(["self"], data);
				history.push(
					getRoute(paths.yourWork, { workspaceId: defaultWorkspaceId }),
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries("self");
			},
		},
	);
}
