import React, { createContext, useContext, useMemo, useState } from "react";
import type { IOrganization } from "@metronome/types/Organization";
import useOrganizations from "@metronome/api/useOrganizations";

type OrganizationDataModel = Array<Partial<IOrganization>>;

type OrganizationDataContextModel = {
	organizations?: OrganizationDataModel;
	// activeOrganization is the select organization by the user when multiple organization are available
	activeOrganization?: string;
	setActiveOrganization: (organization?: string) => void;
	// currentOrganization is the only organization available, a stepInstance is tied to only one organization for example
	currentOrganization?: string;
	setCurrentOrganization: (organization?: string) => void;
};

const OrganizationDataContext = createContext<OrganizationDataContextModel>({
	organizations: {} as OrganizationDataModel,
	activeOrganization: undefined,
	setActiveOrganization: (organization?: string) => null,
	currentOrganization: undefined,
	setCurrentOrganization: (organization?: string) => null,
});

const OrganizationDataProvider: React.FC = ({ children }) => {
	const { data: organizations } = useOrganizations(() => null);
	const [activeOrganization, setActiveOrganization] = useState<
		string | undefined
	>();
	const [currentOrganization, setCurrentOrganization] = useState<
		string | undefined
	>();
	const value: OrganizationDataContextModel = useMemo(
		() => ({
			organizations,
			activeOrganization,
			setActiveOrganization,
			currentOrganization,
			setCurrentOrganization,
		}),
		[organizations, activeOrganization, currentOrganization],
	);

	return (
		<OrganizationDataContext.Provider value={value}>
			{children}
		</OrganizationDataContext.Provider>
	);
};

const useOrganizationData = (): OrganizationDataContextModel => {
	const context = useContext(OrganizationDataContext);
	if (!context) {
		throw new Error("useOrganizationData must be used within a provider");
	}
	return context;
};

export { OrganizationDataProvider, useOrganizationData };
