import type React from "react";
import type { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { useLang } from "./LanguageProvider";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/fr";

// @ts-ignore
import enMessages from "./messages/en.json";
// @ts-ignore
import frMessages from "./messages/fr.json";

export const allMessages = {
	en: enMessages,
	fr: frMessages,
};

const I18nProvider: React.FC = ({ children }: { children?: ReactNode }) => {
	const locale = useLang();
	const messages = allMessages[locale as keyof typeof allMessages];

	return (
		<IntlProvider
			onError={(err) => {
				if (err.code === "MISSING_TRANSLATION") {
					console.warn("Missing translation", err.message);
				}
			}}
			locale={locale}
			messages={messages}
		>
			{children}
		</IntlProvider>
	);
};

export { I18nProvider };
