import type React from "react";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { AxiosError } from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { FallbackView } from "@metronome/components/FallbackView";
import { OrganizationDataProvider } from "@metronome/context/OrganizationData";
import "react-day-picker/dist/style.css";
import { I18nProvider } from "../i18n/i18nProvider";
import { Routes } from "./routing/Routes";
import "react-toastify/dist/ReactToastify.css";
import { ProcessCategoriesProvider } from "@metronome/context/ProcessCategoriesData";
import { WebSocketProvider } from "@metronome/context/WebSocketContext";

const httpErrorCodeNoRetry = [401, 400, 404];
const numberOfRetries = 2;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			staleTime: 20000,
			retry: (failureCount: number, error: unknown) => {
				if (error instanceof AxiosError && error.response?.status) {
					if (httpErrorCodeNoRetry.includes(error.response.status))
						return false;
				}
				return failureCount <= numberOfRetries;
			},
		},
	},
});

const App: React.FC = () => {
	const { inProgress } = useMsal();

	// if the login is in progress, do not render the router to preserve hash in url
	if (inProgress !== InteractionStatus.None) return null;
	return (
		<Suspense fallback={<FallbackView />}>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<WebSocketProvider>
						<OrganizationDataProvider>
							<ProcessCategoriesProvider>
								<I18nProvider>
									<Routes />
									<ToastContainer />
								</I18nProvider>
							</ProcessCategoriesProvider>
						</OrganizationDataProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</WebSocketProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</Suspense>
	);
};

export default App;
