/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import {
	AuthenticatedTemplate,
	useIsAuthenticated,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import { AuthenticationPage } from "../pages/authentication/AuthenticationLayout";
import lazyWithRetry from "./lazyWithRetry";

const MasterLayout = lazyWithRetry(() => import("../MasterLayout"));
const PrivateRoutes = lazyWithRetry(() => import("./PrivateRoutes"));

const Routes: FC = () => {
	const isAuthenticated = useIsAuthenticated();

	if (!isAuthenticated)
		return (
			<UnauthenticatedTemplate>
				<AuthenticationPage />
			</UnauthenticatedTemplate>
		);

	return (
		<AuthenticatedTemplate>
			<MasterLayout>
				<PrivateRoutes />
			</MasterLayout>
		</AuthenticatedTemplate>
	);
};

export { Routes };
