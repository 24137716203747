import { z } from "zod";

export const IOrganization = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
	description: z.string().optional(),
});

export type IOrganization = z.infer<typeof IOrganization>;

export const Organizations = z.array(IOrganization);
export type Organizations = z.infer<typeof Organizations>;
