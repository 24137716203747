import { ComponentType, lazy, LazyExoticComponent } from "react";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const lazyWithRetry = <T extends ComponentType<any>>(
	componentImport: () => Promise<{ default: T }>,
): LazyExoticComponent<T> =>
	lazy(
		() =>
			new Promise((resolve, reject) => {
				const pageHasAlreadyBeenForceRefreshed = JSON.parse(
					window.localStorage.getItem("page-has-been-force-refreshed") ||
						"false",
				);

				componentImport()
					.then((component) => {
						window.localStorage.setItem(
							"page-has-been-force-refreshed",
							"false",
						);
						resolve(component);
					})
					.catch((error) => {
						if (!pageHasAlreadyBeenForceRefreshed) {
							// Assuming that the user is not on the latest version of the application.
							// Let's refresh the page immediately.
							window.localStorage.setItem(
								"page-has-been-force-refreshed",
								"true",
							);
							window.location.reload();
							return { default: () => null };
						}
						// The page has already been reloaded
						// Assuming that user is already using the latest version of the application.
						// Let's let the application crash and raise the error.
						reject(error);
					});
			}),
	);

export default lazyWithRetry;
